import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Content, { HTMLContent } from '../components/Content';
import HeaderVisual from '../components/HeaderVisual';
import BlogList from '../components/BlogList';
import SubTitle, { SubTitleTypes } from '../components/SubTitle';
import constants from '../constants';
import '../sass/pages/blog-post.sass';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  description,
  shrines,
  featuredimage,
  // blessings,
  // tags,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <div className="blogPost">
      {!!featuredimage ? <HeaderVisual image={featuredimage} /> : null}

      <h1 className="blogPost__title">
        {title ? title.replace(/ *\[[^)]*\] */g, '') : ''}
      </h1>
      <div className="blogPost__description">{description}</div>

      <PostContent content={content} />

      <SubTitle type={SubTitleTypes.related} />

      {shrines && shrines.length ? (
        <BlogList type={constants.articleTypes.shrine} value={shrines[0]} />
      ) : null}
    </div>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  shrines: PropTypes.array,
  featuredimage: PropTypes.object,
  // blessings: PropTypes.array,
  // tags: PropTypes.array,
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Seo
        title={`${
          post.frontmatter.title
            ? post.frontmatter.title.replace(/ *\[[^)]*\] */g, '')
            : ''
        } | ヒナタビ`}
        description={`${post.frontmatter.description}`}
        href={location.href}
      />
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        shrines={post.frontmatter.shrines}
        featuredimage={post.frontmatter.featuredimage}
        // blessings={post.frontmatter.blessings}
        // tags={post.frontmatter.tags}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        shrines
        description
        tags
        blessings
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 120, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
